/*------Контейнер для основного блока---------------------------------------------------------------------------------*/
.add-new-vote-type-none__container {
    display: none;
}
.add-new-vote-type-none__container.active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 56;
    width: 100%;
    height: 100vh;
    background: rgba(140, 140, 143, 0.2);
    display: flex;
}
/*------Контейнер для блока с контентом-------------------------------------------------------------------------------*/
.add-new-vote-type-none {
    width: 61.11vw;
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.08);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    position: fixed;
    max-height: 100%;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.add-new-vote-type-none::-webkit-scrollbar {
    width: 8px;
    height: 8px/* ширина всей полосы прокрутки */
}
.add-new-vote-type-none::-webkit-scrollbar-track {
    background: rgba(54, 59, 77, 0.1);        /* цвет зоны отслеживания */
}
.add-new-vote-type-none::-webkit-scrollbar-thumb {
    background-color: gray;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    /* отступ вокруг бегунка */
}
/*------Контейнер для блока с номером вопроса-------------------------------------------------------------------------*/
.add-new-vote-type-none__title {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.44em;
}
.add-new-vote-type-none__title-current-type-question {
    font-size: 0.88em;
    margin-bottom: 1.77em;
    color: rgba(54, 59, 77, 0.4);
    font-weight: 500;
    line-height: 133%;
}
.add-new-vote-type-none__title-number-question {
    font-size: 1.22em;
    color: rgba(54, 59, 77, 0.9);
    line-height: 136%;
    font-weight: 500;
}
.add-new-vote-type-none__title-icon-close {
    width: 0.77em;
    height: 0.77em;
    cursor: pointer;
}
/*------Контейнер для блока с инпутом количество должностных лиц------------------------------------------------------*/
.add-new-vote-type-none__number-positions-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.33em;
}
.add-new-vote-type-none__number-positions-name-positions {
    width: 20em;
    outline: none;
    padding: 0.66em 1.33em 0.66em 1.33em;
    font-size: 1em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    color: rgba(54, 59, 77, 0.9);
    margin-right: 1em;
}
.add-new-vote-type-none__numbers-position {
    width: 8em;
    outline: none;
    padding: 0.66em 1.33em 0.66em 1.33em;
    font-size: 1em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    color: rgba(54, 59, 77, 0.9);
    margin-right: 1em;
}
/*------Контейнер для блока с названием вопроса-----------------------------------------------------------------------*/
.add-new-vote-type-none__name-question {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.33em;
}
.add-new-vote-type-none__name-question-label {
    font-size: 1em;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-type-none__name-question-input {
    width: 100%;
    outline: none;
    padding: 0.66em 1.33em 0.66em 1.33em;
    font-size: 1em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-type-none__name-question-input::placeholder {
    color: rgba(54, 59, 77, 0.4);
}
/*------Контейнер для блока с вариантами ответа-----------------------------------------------------------------------*/
.add-new-vote-type-none__types-variants-answer {
    display: flex;
    flex-direction: column;
}
.add-new-vote-type-none__types-variants-answer-title {
    margin-bottom: 1.33em;
    font-weight: 500;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-type-none__type-input-block {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.33em;
}
.add-new-vote-type-none__type-input-text {
    width: 50vw;
    outline: none;
    padding: 0.66em 1.33em 0.66em 1.33em;
    font-size: 1em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    color: rgba(54, 59, 77, 0.9);
    margin-right: 1em;
}
.add-new-vote-type-none__type-input-text::placeholder {
    color: rgba(54, 59, 77, 0.4);
}

.add-new-vote-type-none__type-input-icons {
    display: inline-flex;
    gap: 1.27em;
    align-items: center;
}
.add-new-vote-type-none__type-input-gray-plus,
.add-new-vote-type-none__type-input-gray-basket {
    width: 1.11em;
    height: 1.11em;
    cursor: pointer;
}
/*------Контейнер для блока с правилом--------------------------------------------------------------------------------*/
.add-new-vote-type-none__rules-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
}
.add-new-vote-type-none__title-rules-block {
    display: inline-flex;
    align-items: center;
    gap: 0.55em;
    cursor: pointer;
}
.add-new-vote-type-none__rules-add-rule-label {
    font-weight: 300;
    font-size: 0.88em;
    line-height: 150%;
    color: #0084FE;
}
.add-new-vote-type-none__rules-icon-plus {
    width: 1.11em;
    height: 1.11em;
}
.add-new-vote-type-none__select-rules-block.active {
    display: flex;
    flex-direction: column;
    margin-top: 1.77em;
}
.add-new-vote-type-none__select-rules-block {
    display: none;
}
.add-new-vote-type-none__select-rules-title {
    font-weight: 500;
    line-height: 133%;
    font-size: 1em;
    color: rgba(54, 59, 77, 0.9);
    margin-bottom: 1.33em;
}
.add-new-vote-type-none__select-rules-inputs-number {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.66em;
    gap: 0.88em;
    width: 100%;
}
.add-new-vote-type-none__select-rules-inputs-number p {
    font-size: 1em;
    line-height: 133%;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
    text-align: center;
}
.add-new-vote-type-none__select-rules-input {
    color: rgba(54, 59, 77, 0.9);
    font-size: 1em;
    outline: none;
    width: 12.83em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    height: 2.66em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
}
.add-new-vote-type-none__select-rules-range {
    display: none;
}
.add-new-vote-type-none__select-rules-range.active {
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 1.77em;
}
.add-new-vote-type-none__select-rules-block-restriction {
    margin-bottom: 0.88em;
}
._question-type {
    width: 20vw;
    margin: 0 0.88em 0 0;
    height: 2.66em;
    font-size: 1em;
}
._question-type-option {
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    cursor: pointer;
}
._question-type-option:hover {
    color: black;
}
._question-type-select {
    display: flex;
    gap: 0.88em;
    overflow: hidden;
    top: 3em;
    align-items: center;
    justify-content: center;
    padding: 1.33em 1.33em;
}
.add-new-vote-type-none__select-rules-restriction {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.add-new-vote-type-none__select-rules-restriction-input-buttons {
    display: flex;
    flex-direction: row;
    gap: 1em;
}
.add-new-vote-type-none__select-rules-restriction-input {
    width: 30vw;
    height: 2.66em;
    border: 0.5px solid rgba(54, 59, 77, 0.3);
    border-radius: 0.44em;
    font-size: 1em;
    padding: 0.66em 1.33em 0.66em 1.33em;
    color: rgba(54, 59, 77, 0.9);
    outline: none;
}
.add-new-vote-type-none__select-rules-block-range {
    /*margin-bottom: 0.88em;*/
    display: none;
}
.add-new-vote-type-none__select-rules-block-range.active {
    padding-top: 0.88em;
    display: initial;
}
.add-new-vote-type-none__select-rules-range-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    background-color: transparent;
}
.add-new-vote-type-none__select-rules-slider-track {
    width: 100%;
    height: 0.27em;
    background-color: rgba(54, 59, 77, 0.4);
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
}
.add-new-vote-type-none__select-rules-range-input::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 0.27em;
}
.add-new-vote-type-none__select-rules-range-input::-moz-range-track {
    -moz-appearance: none;
    height: 0.27em;
}
.add-new-vote-type-none__select-rules-range-input::-ms-track {
    appearance: none;
    height: 0.27em;
}
.add-new-vote-type-none__select-rules-range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.77em;
    width: 0.4em;
    cursor: pointer;
    background-color: #0084FE;
    margin-top: -0.7em;
    pointer-events: auto;
}
.add-new-vote-type-none__select-rules-range-input::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1.77em;
    width: 0.4em;
    cursor: pointer;
    background-color: #0084FE;
    pointer-events: auto;
}
.add-new-vote-type-none__select-rules-range-input::-ms-thumb {
    appearance: none;
    height: 1.77em;
    width: 0.4em;
    cursor: pointer;
    background-color: #0084FE;
    pointer-events: auto;
}

/*------Контейнер для блока с импортом EXCEL--------------------------------------------------------------------------*/
.add-new-vote-type-none__import-excel-block {
    display: inline-flex;
    gap: 0.44em;
    align-items: center;
    margin-bottom: 1.77em;
    cursor: pointer;
}
.add-new-vote-type-none__icon-excel {
    width: 1.44em;
    height: 1.44em;
}
.add-new-vote-type-none__import-excel-btn {
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 300;
    color: #4ED4A9;
}
/*------Контейнер для блока с материалыми голосования-----------------------------------------------------------------*/
.add-new-vote-type-none__add-materials-vote {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
}
/*------Контейнер для блока с кнопкой добавить вопрос-----------------------------------------------------------------*/
.add-new-vote-type-none__add-button-block {
    display: flex;
}
.add-new-vote-type-none__add-btn {
    width: 16em;
    height: 2.66em;
    background: #0084FE;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 500;
}
.add-new-vote-type-none__add-btn:hover {
    color: #0084FE;
    background: #FFFFFF;
    border: 1px solid #0084FE;
}
.css-eg0mwd-MuiSlider-thumb {

}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 700px) {
    .add-new-vote-type-none__container.active {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
        display: flex;
        z-index: 15;
    }
    .add-new-vote-type-none {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 16px 16px 16px;
        box-shadow: -1px 0 8px rgba(54, 58, 72, 0.08);
        background-color: #FFFFFF;
        border-radius: 24px;
        position: initial;
        max-height: 100%;
        overflow: initial;
        top: 0;
        left: 0;
        transform: translate(0%, 0%);
        margin-bottom: 16px;
    }
    .add-new-vote-type-none__title-current-type-question {
        display: none;
    }
    .add-new-vote-type-none__title {
        margin-bottom: 32px;
    }
    .add-new-vote-type-none__title-number-question {
        font-size: 22px;
        line-height: 136%;
    }
    .add-new-vote-type-none__title-icon-close {
        width: 14px;
        height: 14px;
    }
    .add-new-vote-type-none__select-type-question-mobile {
        font-size: 18px;
        line-height: 133%;
        color: rgba(54, 59, 77, 0.9);
        margin-bottom: 8px;
    }
    .add-new-vote-type-none__time-zone-select-container {
        display: flex;
        margin-bottom: 24px;
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
    }
    .add-new-vote-type-none__time-zone-options-container {
        padding: 8px 8px 8px 8px;
    }
    .add-new-vote-type-none__time-zone-option {
        margin: 0 8px 16px 8px;
    }
    .add-new-vote-type-none__add-button-block {
        /*display: none;*/
    }
    .add-new-vote-type-none__add-btn {
        width: 100%;
        height: 56px;
        cursor: default;
        font-size: 18px;
        line-height: 22px;
    }
    .add-new-vote-type-none__name-question {
        gap: 16px;
        margin-bottom: 24px;
    }
    .add-new-vote-type-none__name-question-label {
        font-size: 18px;
    }
    .add-new-vote-type-none__name-question-input {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
        padding: 12px 24px 12px 24px;
    }
    .add-new-vote-type-none__select-type-question-mobile {
        display: flex;
    }
    .add-new-vote-type-none__types-variants-answer-title {
        margin-bottom: 24px;
        font-size: 18px;
    }
    .add-new-vote-type-none__type-input-block {
        width: 100%;
        margin-bottom: 24px;
    }
    .add-new-vote-type-none__type-input-text {
        height: 48px;
        border-radius: 8px;
        width: 100%;
        padding: 12px 24px 12px 24px;
        font-size: 18px;
    }
    .add-new-vote-type-none__type-input-icons {
        gap: 15px;
    }
    .add-new-vote-type-none__type-input-gray-plus,
    .add-new-vote-type-none__type-input-gray-basket {
        width: 20px;
        height: 20px;
    }
    .add-new-vote-type-none__rules-block {
        /*margin-bottom: 32px;*/
    }
    .add-new-vote-type-none__title-rules-block {
        gap: 10px;
        margin-bottom: 0;
    }
    .add-new-vote-type-none__rules-icon-plus {
        width: 18px;
        height: 18px;
    }
    .add-new-vote-type-none__rules-add-rule-label {
        font-size: 12px;
        line-height: 150%;
    }
    .add-new-vote-type-none__import-excel-block {
        margin-bottom: 12px;
        gap: 10px;
    }
    .add-new-vote-type-none__icon-excel {
        width: 18px;
        height: 18px;
    }
    .add-new-vote-type-none__import-excel-btn {
        font-size: 12px;
        line-height: 150%;
    }
    .add-new-vote-type-none__select-rules-block-restriction {
        margin-bottom: 24px;
    }
    .add-new-vote-type-none__select-rules-title {
        margin-bottom: 16px;
        font-size: 16px;
    }
    ._question-type {
        width: 40%;
        height: 36px;
        font-size: 14px;
    }
    ._question-type-select {
        overflow: auto;
        padding: 40px 8px 9px 9px;
    }
    .add-new-vote-type-none__select-rules-restriction-input {
        height: 36px;
        border-radius: 8px;
        font-size: 14px;
    }
    .add-new-vote-type-none__select-rules-inputs-number {
        flex-direction: column;
        font-size: 14px;
        gap: 8px;
    }
    .add-new-vote-type-none__select-rules-input {
        width: 100%;
    }
    .add-new-vote-type-none__select-rules-block-range.active {
        margin-bottom: 12px;
        padding-top: 12px;
    }
    .add-new-vote-type-none__number-positions-block {
        margin-bottom: 32px;
        justify-content: space-between;
        gap: 8px;
    }
    .add-new-vote-type-none__number-positions-name-positions {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
        width: 80%;
        margin-right: 0;
        padding: 12px 24px;
    }
    .add-new-vote-type-none__numbers-position {
        height: 48px;
        font-size: 18px;
        width: 20%;
        padding: 12px 12px;
        margin-right: 0;
    }
    .add-new-vote-type-none__types-variants-answer-grid {
        flex-direction: column;
        margin-bottom: 24px;
    }
    .add-new-vote-type-none__grid-title {
        margin-bottom: 16px;
        font-size: 18px;
    }
    .add-new-vote-type-none__grid-row {
        border-bottom: 0.5px solid rgba(54, 59, 77, 0.4);
        border-right: none;
        padding: 0 0 24px 0;
        margin: 0 0 24px 0;
        gap: 16px;
    }
    .add-new-vote-type-none__grid-column {
        gap: 16px;
    }
    .add-new-vote-type-none__grid-input-text {
        height: 48px;
        border-radius: 8px;
        width: 100%;
        font-size: 18px;
        padding: 12px 24px;
    }
    .add-new-vote-type-none__grid-row-input-block,
    .add-new-vote-type-none__grid-column-input-block {
        gap: 10px;
    }
    .add-new-vote-type-none__checkbox {
        margin: 0 0 24px 0;
        gap: 32px;
        align-items: normal;
    }
    .add-new-vote-type-none__label-checkbox {
        font-size: 16px;
        line-height: 133%;
    }
    .add-new-vote-type-none__checkmark {
        width: 24px;
        height: 24px;
    }
    .add-new-vote-type-none__checkbox_container .add-new-vote-type-none__checkmark:after {
        left: 5px;
        top: 3px;
        width: 9px;
        height: 9px;
    }

}