.preloader-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    background: rgba(140, 140, 143, 0.9);
}
.preloader-call-voting {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 180px;
    height: 180px;
    background: transparent;
    border: 3px solid rgba(54, 59, 77, 0.6);
    border-radius: 50%;
    text-align: center;
    line-height: 170px;
    font-weight: 600;
    font-family: sans-serif;
    font-size: 16px;
    color: #0084FE;
    letter-spacing: 4px;
    text-transform: uppercase;
}
.preloader-call-voting:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 180px;
    height: 180px;
    border: 3px solid transparent;
    border-top: 3px solid #0084FE;
    border-right: 3px solid #0084FE;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
}
.preloader-ring {
    display: block;
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: animate 2s linear infinite;
}
.preloader-ring:before {
    content: '';
    position:  absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #0084FE;
    top: -6px;
    right: -8px;
}
@keyframes animateC {
    0%
    {
        transform:rotate(0deg);
    }
    100%
    {
        transform:rotate(360deg);
    }
}
@keyframes animate {
    0%
    {
        transform:rotate(45deg);
    }
    100%
    {
        transform:rotate(405deg);
    }
}