.registration-pass-require-modal__wrapper {
    z-index: 18;
    position: absolute;
    top: 4.66em;
    left: 0;
    opacity: 0;
    pointer-events: none;
}
.registration-pass-require-modal__wrapper.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
.registration-pass-require-modal__content {
    position: relative;
    display: inline-block;
}
.registration-pass-require-modal__content.active {
    transform: scale(1);
}
/* Текст*/
.registration-pass-require-modal__content-text {
    display: inline-flex;
    gap: 0.8em;
    align-items:flex-start;
    width: 37.1em;
    background: #FFFFFF;
    color: rgba(54, 59, 77, 0.9);
    padding: 0.8em 1.3em 0.8em 0.6em;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 124%;
    position: absolute;
    filter: drop-shadow(0px 3px 16px rgba(54, 58, 72, 0.08));
    border-radius: 2px;
}
/*Стрелка */
.registration-pass-require-modal__content-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10%;
    margin-left: -1.9em;
    margin-top: -5.5em;
    border-width: 0.7em;
    transform: rotate(180deg);
    border-style: solid;
    border-color: #FFFFFF transparent transparent transparent;
    filter: drop-shadow(0px 3px 16px rgba(54, 58, 72, 0.08));
}
.content-text__warning-icon {
    margin-top: 4px;
    width: 1.1em;
    height: 1.1em;
}
.content-text__close-icon {
    margin-top: 4px;
    cursor: pointer;
    width: 0.8em;
    height: 0.8em;
}
.content-text__warning-icon-mobile {
    display: none;
}
.content-text__close-icon-mobile {
    display: none;
}
/*----------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 1215px) {
    .registration-pass-require-modal__content-text::after  {
        margin-top: -5.3em;
    }
}
@media (max-width: 1010px) {
    .registration-pass-require-modal__content-text::after  {
        margin-top: -5.3em;
        margin-left: -1.9em;
    }
}
@media (max-width: 500px) {
    .registration-pass-require-modal__wrapper {
        top: 290px;
    }
    .registration-pass-require-modal__content-text  {
        width: 343px;
        font-size: 12px;
        background-color: #373C4E;
        border-radius: 24px;
        color: #FFFFFF;
        line-height: 20px;
        letter-spacing: -0.01em;
    }
    .registration-pass-require-modal__content-text::after {
        display: none;
    }
    .content-text__warning-icon {
        display: none;
    }
    .content-text__warning-icon-mobile {
        display: initial;
    }
    .content-text__close-icon {
        display: none;
    }
    .content-text__close-icon-mobile {
        display: initial;
        cursor: pointer;
    }
}