/*------Cтилизация общего блока для select, переключения между страницами и поиска в таблице -------------------------*/
.navigation-menu__pagination-search-block-org {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #363B4D;
}
/*------Cтилизация для выбора показа количества элементов-------------------------------------------------------------*/
.pagination-search-block-org__show-page {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    margin-right: 2em;
    height: 1.5em;
    letter-spacing: 0.0025em;
    cursor: pointer;
    position: relative;
}
.pagination-search-block-org__text {
    margin: 0 1em 0 0;
}
.pagination-search-block-org__arrow-count-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25em 0.5em 0.25em 0.25em;
    border-radius: 0.5em;
    border: 1px solid rgba(54, 59, 77, 0.2);
    width: 4.25em;
}
.pagination-search-block-org__arrow {
    background-image: url('../../img/options-dropdown-arrow.svg');
    width: 0.62em;
    height: 0.37em;
    background-size: cover;
    margin-left: 0.7em;
    background-repeat: no-repeat;
}
.pagination-search-block-org__options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 4.25em;
    top: 2em;
    right: 0;
    border: 0.5px solid #EEEEEE;
    box-shadow: 0 0.25em 0.5em rgba(54, 59, 77, 0.1);
    cursor: default;
    overflow: hidden;
    border-radius: 0.5em;
    z-index: 1;
}
.pagination-search-block-org__option-container {
    display: flex;
    height: 2em;
    background: #FFFFFF;
    cursor: pointer;
}
.pagination-search-block-org__option-container:last-of-type {
    border: none;
}
.pagination-search-block-org__option-container:hover {
    background: #cfcdcd;
}
.pagination-search-block-org__option {
    padding: 0.25em 2.5em 0.25em 0.5em;
}
/*------Cтилизация для переключения страниц--------------------------------------------------------------------------*/
.pagination-search-block-org__change-page {
    display: flex;
    align-items: center;
    gap: 2.5em;
    padding-right: 2.5em;
    height: 1.5em;
}
.change-page__rows {
    display: flex;
    align-items: center;
    gap: 3.12em;
}
.change-page__counter-page {
    width: 3.87em;
    text-align: center;
}
.change-page__rows-left {
    width: 0.43em;
    height: 0.68em;
    cursor: pointer;
}
.change-page__rows-right {
    width: 0.43em;
    height: 0.68em;
    cursor: pointer;
}
/*------Cтилизация для поиска по таблице-----------------------------------------------------------------------------*/
.pagination-search-block-org__search-table-org {
    display: flex;
    position: relative;
    margin: 0 0 0 auto;
    width: 500px;
}
.pagination-search-block-org__search-table-org-input {
    outline: none;
    background-color: #FFFFFF;
    height: 2.5em;
    box-shadow: 0 0.25em 0.5em rgba(101, 105, 117, 0.05);
    border-radius: 0.5em;
    font-size: 0.87em;
    line-height: 118%;
    color: rgba(54, 59, 77, 0.9);
    padding-left: 3.5em;
    width: 100%;
}
.search-table-org__search-table-org-icon {
    position: absolute;
    top: 0.55em;
    left: 1em;
    opacity: 0.4;
    width: 1.12em;
    height: 1.12em;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .navigation-menu__pagination-search-block-org {
        font-size: 14px;
    }
}
@media (max-width: 1350px) {
    .navigation-menu__pagination-search-block-org {
        font-size: 12px;
    }
    .pagination-search-block-org__search-table-org {
        width: 250px;
    }
}
@media (max-width: 900px) {
    .navigation-menu__pagination-search-block-org {
        font-size: 10px;
    }
}
@media (max-width: 700px) {
    .navigation-menu__pagination-search-block-org {
        font-size: 8px;
    }
    .pagination-search-block-org__search-table-org {
        width: 200px;
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .pagination-search-block-org__text {
        margin: 0 0.38em 0 0;
        font-size: 12px;
    }
    .pagination-search-block-org__arrow-count-page {
        font-size: 12px;
    }
    .pagination-search-block-org__options-container {
        font-size: 12px;
    }
    .pagination-search-block-org__change-page {
        gap: 4px;
    }
    .change-page__counter-page {
        font-size: 10px;
    }
}
@media (max-width: 470px) {
    .pagination-search-block-org__search-table-org {
        width: 100%;
    }
    .pagination-search-block-org__search-table-org-input {
        height: 3.5em;
        font-size: 1.1em;
        line-height: 100%;
        padding-left: 4.0em;
    }
    .search-table-org__search-table-org-icon {
        top: 1.3em;
        left: 1.5em;
        width: 1.5em;
        height: 1.5em;
    }
}