/*------Контейнер для основного блока---------------------------------------------------------------------------------*/
.add-new-vote-type-position-single__container {
    display: none;
}
.add-new-vote-type-position-single__container.active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 56;
    width: 100%;
    height: 100vh;
    background: rgba(140, 140, 143, 0.2);
    display: flex;
}
/*------Контейнер для блока с контентом-------------------------------------------------------------------------------*/
.add-new-vote-type-position-single {
    width: 61.11vw;
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.08);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    position: fixed;
    max-height: 100%;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.add-new-vote-type-position-single::-webkit-scrollbar {
    width: 8px;
    height: 8px/* ширина всей полосы прокрутки */
}
.add-new-vote-type-position-single::-webkit-scrollbar-track {
    background: rgba(54, 59, 77, 0.1);        /* цвет зоны отслеживания */
}
.add-new-vote-type-position-single::-webkit-scrollbar-thumb {
    background-color: gray;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    /* отступ вокруг бегунка */
}
/*------Контейнер для блока с номером вопроса-------------------------------------------------------------------------*/
.add-new-vote-type-position-single__title {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.44em;
}
.add-new-vote-type-position-single__title-current-type-question {
    font-size: 0.88em;
    margin-bottom: 1.77em;
    color: rgba(54, 59, 77, 0.4);
    font-weight: 500;
    line-height: 133%;
}
.add-new-vote-type-position-single__title-number-question {
    font-size: 1.22em;
    color: rgba(54, 59, 77, 0.9);
    line-height: 136%;
    font-weight: 500;
}
.add-new-vote-type-position-single__title-icon-close {
    width: 0.77em;
    height: 0.77em;
    cursor: pointer;
}
/*------Контейнер для блока с названием вопроса-----------------------------------------------------------------------*/
.add-new-vote-type-position-single__name-question {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.33em;
}
.add-new-vote-type-position-single__name-question-label {
    font-size: 1em;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-type-position-single__name-question-input {
    width: 100%;
    outline: none;
    padding: 0.66em 1.33em 0.66em 1.33em;
    font-size: 1em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-type-position-single__name-question-input::placeholder {
    color: rgba(54, 59, 77, 0.4);
}
/*------Контейнер для блока с вариантами ответа-----------------------------------------------------------------------*/
.add-new-vote-type-position-single__types-variants-answer {
    display: flex;
    flex-direction: column;
}
.add-new-vote-type-position-single__types-variants-answer-title {
    margin-bottom: 1.33em;
    font-weight: 500;
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-type-position-single__type-input-block {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.33em;
}
.add-new-vote-type-position-single__type-input-text {
    width: 50vw;
    outline: none;
    padding: 0.66em 1.33em 0.66em 1.33em;
    font-size: 1em;
    border: 0.5px solid rgba(54, 59, 77, 0.4);
    border-radius: 0.44em;
    color: rgba(54, 59, 77, 0.9);
    margin-right: 1em;
}
.add-new-vote-type-position-single__type-input-text::placeholder {
    color: rgba(54, 59, 77, 0.4);
}

.add-new-vote-type-position-single__type-input-icons {
    display: inline-flex;
    gap: 1.27em;
    align-items: center;
}
.add-new-vote-type-position-single__type-input-gray-plus,
.add-new-vote-type-position-single__type-input-gray-basket {
    width: 1.11em;
    height: 1.11em;
    cursor: pointer;
}
/*------Контейнер для блока с импортом EXCEL--------------------------------------------------------------------------*/
.add-new-vote-type-position-single__import-excel-block {
    display: inline-flex;
    gap: 0.44em;
    align-items: center;
    margin-bottom: 1.77em;
    cursor: pointer;
}
.add-new-vote-type-position-single__icon-excel {
    width: 1.44em;
    height: 1.44em;
}
.add-new-vote-type-position-single__import-excel-btn {
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 300;
    color: #4ED4A9;
}
/*------Контейнер для блока с материалыми голосования-----------------------------------------------------------------*/
.add-new-vote-type-position-single__add-materials-vote {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
}
/*------Контейнер для блока с кнопкой добавить вопрос-----------------------------------------------------------------*/
.add-new-vote-type-position-single__add-button-block {
    display: flex;
}
.add-new-vote-type-position-single__add-btn {
    width: 16em;
    height: 2.66em;
    background: #0084FE;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 500;
}
.add-new-vote-type-position-single__add-btn:hover {
    color: #0084FE;
    background: #FFFFFF;
    border: 1px solid #0084FE;
}
.add-new-vote-type-position-single__error-message {
    font-weight: 300;
    font-size: 0.80em;
    line-height: 110%;
    color: #FF4970;
    margin: 0 auto 0.8em 0;
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 700px) {
    .add-new-vote-type-position-single__container.active {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
        display: flex;
        z-index: 15;
    }
    .add-new-vote-type-position-single {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 16px 16px 16px;
        box-shadow: -1px 0 8px rgba(54, 58, 72, 0.08);
        background-color: #FFFFFF;
        border-radius: 24px;
        position: initial;
        max-height: 100%;
        overflow: initial;
        top: 0;
        left: 0;
        transform: translate(0%, 0%);
        margin-bottom: 16px;
    }
    .add-new-vote-type-position-single__title-current-type-question {
        display: none;
    }
    .add-new-vote-type-position-single__title {
        margin-bottom: 32px;
    }
    .add-new-vote-type-position-single__title-number-question {
        font-size: 22px;
        line-height: 136%;
    }
    .add-new-vote-type-position-single__title-icon-close {
        width: 14px;
        height: 14px;
    }
    .add-new-vote-type-position-single__select-type-question-mobile {
        font-size: 18px;
        line-height: 133%;
        color: rgba(54, 59, 77, 0.9);
        margin-bottom: 8px;
    }
    .add-new-vote-type-position-single__time-zone-select-container {
        display: flex;
        margin-bottom: 24px;
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
    }
    .add-new-vote-type-position-single__time-zone-options-container {
        padding: 8px 8px 8px 8px;
    }
    .add-new-vote-type-position-single__time-zone-option {
        margin: 0 8px 16px 8px;
    }
    .add-new-vote-type-position-single__add-button-block {
        width: 100%;
    }
    .add-new-vote-type-position-single__add-btn {
        width: 100%;
        height: 56px;
        cursor: default;
        font-size: 18px;
        line-height: 22px;
    }
    .add-new-vote-type-position-single__add-btn:hover {
        border: none;
        background: #0084FE;
        color: #FFFFFF;
    }
    .add-new-vote-type-position-single__name-question {
        gap: 16px;
        margin-bottom: 24px;
    }
    .add-new-vote-type-position-single__name-question-label {
        font-size: 18px;
    }
    .add-new-vote-type-position-single__name-question-input {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
        padding: 12px 24px 12px 24px;
    }
    .add-new-vote-type-position-single__select-type-question-mobile {
        display: flex;
    }
    .add-new-vote-type-position-single__types-variants-answer-title {
        margin-bottom: 24px;
        font-size: 18px;
    }
    .add-new-vote-type-position-single__type-input-block {
        width: 100%;
        margin-bottom: 24px;
    }
    .add-new-vote-type-position-single__type-input-text {
        height: 48px;
        border-radius: 8px;
        width: 100%;
        padding: 12px 24px 12px 24px;
        font-size: 18px;
    }
    .add-new-vote-type-position-single__type-input-icons {
        gap: 15px;
    }
    .add-new-vote-type-position-single__type-input-gray-plus,
    .add-new-vote-type-position-single__type-input-gray-basket {
        width: 20px;
        height: 20px;
    }
    .add-new-vote-type-position-single__rules-block {
        margin-bottom: 32px;
    }
    .add-new-vote-type-position-single__title-rules-block {
        gap: 10px;
    }
    .add-new-vote-type-position-single__rules-icon-plus {
        width: 20px;
        height: 20px;
    }
    .add-new-vote-type-position-single__rules-add-rule-label {
        font-size: 16px;
        line-height: 150%;
    }
    .add-new-vote-type-position-single__import-excel-block {
        margin-bottom: 32px;
        gap: 10px;
    }
    .add-new-vote-type-position-single__icon-excel {
        width: 20px;
        height: 20px;
    }
    .add-new-vote-type-position-single__import-excel-btn {
        font-size: 16px;
        line-height: 150%;
    }
    .add-new-vote-type-position-single__select-rules-block-restriction {
        margin-bottom: 24px;
    }
    .add-new-vote-type-position-single__select-rules-title {
        margin-bottom: 16px;
        font-size: 16px;
    }
    ._question-type {
        width: 40%;
        height: 36px;
        font-size: 14px;
    }
    ._question-type-select {
        overflow: auto;
        padding: 40px 8px 9px 9px;
    }
    .add-new-vote-type-position-single__select-rules-restriction-input {
        height: 36px;
        border-radius: 8px;
        font-size: 14px;
    }
    .add-new-vote-type-position-single__select-rules-inputs-number {
        flex-direction: column;
        font-size: 14px;
        gap: 8px;
    }
    .add-new-vote-type-position-single__select-rules-input {
        width: 100%;
    }
    .add-new-vote-type-position-single__number-positions-block {
        margin-bottom: 32px;
        justify-content: space-between;
        gap: 8px;
    }
    .add-new-vote-type-position-single__number-positions-name-positions {
        height: 48px;
        border-radius: 8px;
        font-size: 18px;
        width: 80%;
        margin-right: 0;
        padding: 12px 24px;
    }
    .add-new-vote-type-position-single__numbers-position {
        height: 48px;
        font-size: 18px;
        width: 20%;
        padding: 12px 12px;
        margin-right: 0;
    }
    .add-new-vote-type-position-single__types-variants-answer-grid {
        flex-direction: column;
        margin-bottom: 24px;
    }
    .add-new-vote-type-position-single__grid-title {
        margin-bottom: 16px;
        font-size: 18px;
    }
    .add-new-vote-type-position-single__grid-row {
        border-bottom: 0.5px solid rgba(54, 59, 77, 0.4);
        border-right: none;
        padding: 0 0 24px 0;
        margin: 0 0 24px 0;
        gap: 16px;
    }
    .add-new-vote-type-position-single__grid-column {
        gap: 16px;
    }
    .add-new-vote-type-position-single__grid-input-text {
        height: 48px;
        border-radius: 8px;
        width: 100%;
        font-size: 18px;
        padding: 12px 24px;
    }
    .add-new-vote-type-position-single__grid-row-input-block,
    .add-new-vote-type-position-single__grid-column-input-block {
        gap: 10px;
    }
    .add-new-vote-type-position-single__checkbox {
        margin: 0 0 24px 0;
        gap: 32px;
        align-items: normal;
    }
    .add-new-vote-type-position-single__label-checkbox {
        font-size: 16px;
        line-height: 133%;
    }
    .add-new-vote-type-position-single__checkmark {
        width: 24px;
        height: 24px;
    }
    .add-new-vote-type-position-single__checkbox_container .add-new-vote-type-position-single__checkmark:after {
        left: 5px;
        top: 3px;
        width: 9px;
        height: 9px;
    }
    .add-new-vote-type-position-single__error-message {
        font-size: 12px;
        line-height: 16px;
        margin: 0 auto 12px 0;
    }
}