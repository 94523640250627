/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.add-new-vote-created-question {
    margin-bottom: 2em;
    border-bottom: 1px dashed rgba(54, 59, 77, 0.4);
    padding-bottom: 2em;
}
/*------Контейнер для блока с названием вопроса-----------------------------------------------------------------------*/
.add-new-vote-created-question__question-block {
    display: flex;
    flex-direction: column;
    padding: 1.77em 1.77em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    width: 49.444vw;
}
.add-new-vote-created-question__name-type-questions {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.88em;
    padding-bottom: 0.88em;
    word-break: break-word;
}
.add-new-vote-created-question__list-answer {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.33em;
    padding-bottom: 1.33em;
    border-bottom: 0.5px solid rgba(54, 59, 77, 0.2);
    word-break: break-word;
}
.add-new-vote-created-question__list-answer-grid-row {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.add-new-vote-created-question__list-answer-grid-columns {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.add-new-vote-created-question__list-answer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.44em;
}
.add-new-vote-created-question__list-answer-square-icon {
    width: 0.55em;
    height: 0.55em;
}
.add-new-vote-created-question__list-answer-item {
    font-size: 0.88em;
    color: rgba(54, 59, 77, 0.9);
}
.add-new-vote-created-question__list-answer-grid {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.33em;
    padding-bottom: 1.33em;
    border-bottom: 0.5px solid rgba(54, 59, 77, 0.2);
    word-break: break-word;
}
.add-new-vote-created-question__list-answer-title {
    font-size: 0.88em;
    line-height: 133%;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    word-break: break-word;
}
.add-new-vote-created-question__name-question {
    font-size: 1em;
    line-height: 133%;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    margin-bottom: 0.88em;
    word-break: break-word;
}
.add-new-vote-created-question__type-question {
    font-size: 0.88em;
    line-height: 150%;
    color: rgba(54, 59, 77, 0.4);
    font-weight: 400;
}
/*------Контейнер для блока с кнопками редактировать и удалить--------------------------------------------------------*/
.add-new-vote-created-question__buttons-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.add-new-vote-created-question__edit-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.72em;
    cursor: pointer;
}
.add-new-vote-created-question__delete-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.72em;
    cursor: pointer;
}
.add-new-vote-created-question__edit-button p {
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 300;
    color: #0084FE;
}
.add-new-vote-created-question__delete-button p {
    font-size: 0.88em;
    line-height: 150%;
    font-weight: 300;
    color: #FF4970;
}
.add-new-vote-created-question__icon-edit,
.add-new-vote-created-question__icon-delete {
    width: 1em;
    height: 1em;
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 700px) {
    .add-new-vote-created-question__question-block {
        width: 100%;
        padding: 16px 16px 16px 16px;
    }
    .add-new-vote-created-question__name-type-questions {
        margin-bottom: 16px;
        padding-bottom: 16px;
    }
    .add-new-vote-created-question__list-answer,
    .add-new-vote-created-question__list-answer-grid {
        margin-bottom: 16px;
        padding-bottom: 16px;
        gap: 16px;
    }
    .add-new-vote-created-question__name-question {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .add-new-vote-created-question__type-question {
        font-size: 16px;
    }
    .add-new-vote-created-question__buttons-block {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .add-new-vote-created-question__icon-edit {
        display: none;
    }
    .add-new-vote-created-question__icon-delete {
        display: none;
    }
    .add-new-vote-created-question__edit-button {
        font-size: 16px;
        width: 100%;
        height: 48px;
        border: 1px solid #0084FE;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }
    .add-new-vote-created-question__delete-button {
        font-size: 16px;
        width: 100%;
        height: 48px;
        border: 1px solid #FF4970;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
    }
    .add-new-vote-created-question__list-answer-grid-row,
    .add-new-vote-created-question__list-answer-grid-columns {
        gap: 16px;
    }
    .add-new-vote-created-question__list-answer-title {
        font-size: 16px;
    }
    .add-new-vote-created-question__list-answer-row {
        font-size: 16px;
    }
}