/*------Основной стиль для модального окна----------------------------------------------------------------------------*/
.filters-modal {
    position: absolute;
    width: 69em;
    max-height: 644px;
    background-color: #FFFFFF;
    top: 3em;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 47;
    pointer-events: none;
    transition: 0.5s;
    box-shadow: 0 4px 8px 0 rgba(54, 59, 77, 0.1);
    transform: translate(-100%);
}
.filters-modal.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: all;
}
/*------Стилизация для контента модального окна-----------------------------------------------------------------------*/
.filters-modal__content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2em 2em;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    transform: scale(0.5);
    transition: 0.4s all;
    gap: 2em;
    box-shadow: 0 4px 8px 0 rgba(54, 59, 77, 0.1);
}
.filters-modal__content.active {
    transform: scale(1);
}
/*------Стилизация для заголовка--------------------------------------------------------------------------------------*/
.filters-modal__content-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.filters-modal__content-title img {
    cursor: pointer;
    width: 0.93em;
    height: 0.93em;
}
.filters-modal__content-title-mobile {
    display: none;
    width: 88vw;
    font-size: 12px;
    color: rgba(54, 59, 77, 0.4);
    line-height: 18px;
}
.filters-modal__content-title-mobile img {
    cursor: pointer;
    width: 10px;
    height: 10px;
}
/*------Общий стиль для названия блоков с чекбоксами, датами начали регистрации и голосования-------------------------*/
.filters-modal__content h3 {
    color: #363B4D;
    opacity: 90%;
    font-size: 1.12em;
    font-weight: 600;
    line-height: 133%;
}
.filters-modal__content h4 {
    display: none;
}
/*------Стилизация для div c чекбоксами по статусу голосования--------------------------------------------------------*/
.filters-modal__content-checkboxes-status-vote {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: start; */
    gap: 1.5em;
    line-height: 110%;
    font-size: 1.12em;
    color: #363B4D;
    opacity: 90%;
}
/*------Стилизация для div c чекбоксами по типу голосования------------------------------------------------------------*/
.filters-modal__content-checkboxes-type-vote {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.content-checkboxes-type-vote {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: start; */
    gap: 1.5em;
    line-height: 110%;
    font-size: 1.12em;
    color: #363B4D;
    opacity: 90%;
}
/*------Общие стиля для div с датой начала регистрации,div с датой начала голосования---------------------------------*/
.filters-modal__content-date-start-reg, .filters-modal__content-date-start-vote {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}
.content-date-start-reg__select-dates {
    display: flex;
    gap: 1em;
    align-items: center;
}
.content-date-start-reg__increment-bnt {
    display: flex;
    gap: 1em;
    align-items: center;
}
.content-date-start-reg, .content-date-start-vote {
    display: flex;
    flex-direction: row;
    gap: 1em;
    font-size: 1.12em;
    font-weight: 400;
    line-height: 110%;
    color: #363B4D;
    opacity: 90%;
}
.content-date-start-reg__start-select-date, .content-date-start-vote__start-select-date {
    outline: none;
    font-size: 0.81em;
    border: 1px rgba(54, 59, 77, 0.3) solid;
    border-radius: 0.5em;
    padding: 1em 1em 1em 1em;
    width: 12em;
    height: 3em;
    opacity: 90%;
    cursor: pointer;
}
.content-date-start-reg__end-select-date, .content-date-start-vote__end-select-date {
    outline: none;
    font-size: 0.81em;
    border: 1px rgba(54, 59, 77, 0.3) solid;
    border-radius: 0.5em;
    padding: 1.5em 1.5em 1.5em 1.5em;
    width: 12em;
    height: 1em;
    opacity: 90%;
    cursor: pointer;
}
.filters-modal__buttons-container {
    display: flex;
    margin: 0 auto 0 0;
}
.filters-modal__button-apply {
    width: 12.5em;
    height: 3em;
    background-color: #0084FE;
    color: #FFFFFF;
    font-size: 1em;
    font-weight: 500;
    line-height: 133%;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 1.25em 0 0;
}
.filters-modal__button-reset {
    width: 12.5em;
    height: 3em;
    background-color: #FBFBFC;
    color: #0084FE;
    font-size: 1em;
    font-weight: 500;
    line-height: 133%;
    border: 1px #0084FE solid;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
}

/*-----Адаптивная версия----------------------------------------------------------------------------------------------*/
@media (max-width: 580px) {
    .filters-modal__content-title img {
        display: none;
    }
    .filters-modal__content-title-mobile {
        display: flex;
        justify-content: space-between;
    }
    .filters-modal__content-checkboxes-status-vote {
        gap: 12px;
        min-width: 500px;
        display: flex;
        flex-direction: column;
    }
    .content-checkboxes-type-vote {
        gap: 8px;
    }
    .filters-modal__content h4 {
        display: inherit;
        color: #363B4D;
        opacity: 90%;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    .filters-modal__content h3 {
        display: none;
    }
    .content-date-start-reg__start-select-date,
    .content-date-start-vote__start-select-date,
    .content-date-start-reg__end-select-date,
    .content-date-start-vote__end-select-date {
        width: 135px;
        height: 38px;
        padding: 8px 12px 8px 12px;
    }
    .content-date-start-reg, .content-date-start-vote {
        font-size: 16px;
        flex-direction: column-reverse;
    }

}
@media (max-width: 420px) {
    .filters-modal {
        left: -20px;
        top: 160px;
    }
    .filters-modal__content {
        padding: 24px 0 8px 16px;
        box-shadow: none;
        gap: normal;
    }
    .filters-modal__content h4 {
        padding-bottom: 16px;
    }
    .filters-modal__content-checkboxes-status-vote {
        padding-bottom: 24px;
    }
    .filters-modal__content-checkboxes-type-vote {
        gap: normal;
        padding-bottom: 24px;
    }
    .filters-modal__content-date-start-reg {
        gap: normal;
        padding-bottom: 24px;
    }
    .filters-modal__content-date-start-vote {
        gap: normal;
    }
    .filters-modal__buttons-container {
        padding-top: 10px;
    }
    .filters-modal__button-apply, .filters-modal__button-reset {
        margin: 0 10px 0 0;
        width: 150px;
        height: 40px;
        font-size: 12px;
    }
    .content-date-start-reg__select-dates {
        flex-direction: column;
        align-items: flex-start;
    }
}



