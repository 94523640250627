.app {
    font-family: 'Inter';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    color: #000000;
    min-width: 300px;
    background-color: #FBFBFC;
    min-height: 100%;
}
