.statistics-org-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.statistics-org-settings__heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: rgba(54, 59, 77, 0.9);
    margin: 0 auto 1.77em 0;
}
.statistics-org-settings-table {
    display: table;
    width: 100%;
    margin-bottom: 1.33em;
}
.statistics-org-settings-table-row {
    display: table-row;
}
.statistics-org-settings-table-cell-title {
    border: 1px solid rgba(54, 59, 77, 0.2);
    font-weight: 600;
    display: table-cell;
    padding: 0.88em 0.88em;
    font-size: 1em;
    width: 30%;
    color: #363B4D;
    opacity: 90%;
}
.statistics-org-settings-table-cell {
    border: 1px solid rgba(54, 59, 77, 0.2);
    display: table-cell;
    padding: 0.88em 0.88em;
    font-size: 1em;
    max-width: 30em;
    word-break: break-all;
    text-align: center;
    color: #363B4D;
    opacity: 90%;
}
.statistics-org-settings-table-body {
    display: table-row-group;
}
.statistics-org-settings-table-body-one-row {
    display: table-row-group;
}
/*-----Стили для мобильной версии-------------------------------------------------------------------------------------*/
.statistics-org-settings-table-one-row-mobile {
    display: none;
}
.statistics-org-settings-table-body-mobile {
    display: none;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .statistics-org-settings-table-cell-title {
        font-size: 14px;
        width: 20%;
        padding: 8px 8px;
    }
    .statistics-org-settings-table-cell {
        font-size: 14px;
        padding: 8px 8px;
    }
}
@media (max-width: 1070px) {
    .statistics-org-settings-table-cell-title {
        font-size: 12px;
        width: 20%;
        padding: 8px 8px;
    }
    .statistics-org-settings-table-cell {
        font-size: 12px;
        padding: 8px 8px;
    }
}
@media (max-width: 950px) {
    .statistics-org-settings-table {
        margin-bottom: 24px;
    }
    .statistics-org-settings__heading {
        display: none;
    }
    .statistics-org-settings-table-body {
        display: none;
    }
    .statistics-org-settings-table-body-one-row {
        display: none;
    }
    .statistics-org-settings-table-one-row-mobile {
        display: flex;
        flex-direction: column;
    }
    .statistics-org-settings-table-body-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .statistics-org-settings-table-row-mobile {
        display: inline-grid;
        width: 100%;
    }
    .statistics-org-settings-table-row-mobile div:first-child {
        color: rgba(54, 59, 77, 0.4);
    }
    .statistics-org-settings-table-cell-title {
        text-align: center;
        width: 100%;
        font-size: 14px;
    }
    .statistics-org-settings-table-cell {
        max-width: 100%;
        font-size: 14px;
    }
}

