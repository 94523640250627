/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.container__profile-user {
    display: flex;
    flex-direction: column;
    min-height: calc(40vh - 180px);
    font-size: 18px;
    margin-bottom: 6.22em;
}
/*------Контейнер для основного блока с контентом---------------------------------------------------------------------*/
.profile-user {
    display: flex;
    flex-direction: row;
    gap: 0.88em;
}
/*------Контейнер для блока с данными пользователя(короткая версия)---------------------------------------------------*/
.profile-user__personal-data-user {
    display: flex;
    flex-direction: column;
    padding: 1.33em 1.33em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    align-self: flex-start;
    width: 30vw;
}
.personal-data-user__main-data-user.active {
    display: none;
}
.personal-data-user__icon-photo {
    width: 26.667vw;
    height: 8em;
    margin-bottom: 0.88em;
    flex-wrap: wrap;
}
.personal-data-user__main-data-user {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    word-break: break-word;
}
.main-data-user__username {
    line-height: 100%;
    margin-bottom: 0.44em;
    font-weight: 600;
    color: #363B4D;
    opacity: 90%;
}
.main-data-user__email {
    font-size: 0.88em;
    color: rgba(54, 59, 77, 0.4);
    line-height: 124%;
    margin-bottom: 0.88em;
    word-break: break-word;
}
.main-data-user__timezone {
    display: inline-flex;
    gap: 0.5em;
    /*margin-bottom: 1.77em;*/
}
.main-data-user__timezone-icon {
    width: 0.72em;
    height: 0.72em;
}
.main-data-user__timezone-current {
    font-size: 0.77em;
    color: #4569FF;
    line-height: 118%;
}
.main-data-user__edit-button {
    cursor: pointer;
    font-size: 0.88em;
    line-height: 124%;
    color: #0084FE;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #0084FE;
    height: 3em;
}
.main-data-user__edit-button:hover {
    background-color: #0084FE;
    color: #FFFFFF;
}
/*------Контейнер для блока с редактированием данных пользователя-----------------------------------------------------*/
.personal-data-user__edit-main-data-user {
    display: none;
}
.personal-data-user__edit-main-data-user.active {
    display: flex;
    flex-direction: column;
    position: relative;
}
.profile-user__error-message {
    margin-bottom: 0.88em;
    font-size: 0.88em;
    color: #FF4970;
}
.edit-main-data-user__email {
    font-size: 0.88em;
    line-height: 124%;
    color: rgba(54, 59, 77, 0.4);
    margin-bottom: 1.33em;
    word-break: break-word;
}
.edit-main-data-user__title-change-data {
    font-size: 1em;
    font-weight: 600;
    color: #363B4D;
    line-height: 110%;
    margin-bottom: 1.33em;
    opacity: 90%;
}
.edit-main-data-user__surname {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 0.88em;
}
.edit-main-data-user__name {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 0.88em;
}
.edit-main-data-user__middle-name {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.77em;
}
.edit-main-data-user__surname-label,
.edit-main-data-user__name-label,
.edit-main-data-user__middle-name-label {
    font-size: 1em;
    color: rgba(54, 59, 77, 0.9);
    line-height: 133%;
}
.edit-main-data-user__surname-field-input,
.edit-main-data-user__name-field-input,
.edit-main-data-user__middle-name-field-input {
    border-radius: 0.44em;
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    outline: none;
    color: #363B4D;
    opacity: 90%;
    line-height: 133%;
    font-size: 1em;
    padding: 0.66em 1.33em;
    height: 2.66em;
}
/*------Стилизация блока с выбором часового пояса---------------------------------------------------------------------*/
.edit-main-data-user__time-zone-main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.77em;
    gap: 0.8em;
    position: relative;
}
.edit-main-data-user__time-zone-main-container.active {
    display: none;
}
.edit-main-data-user__time-zone-main-container__error-message {
    position: absolute;
    font-size: 0.7em;
    line-height: 143%;
    color: #FF4970;
    left: 0;
    top: 7em;
    display: none;
}
.edit-main-data-user__time-zone-heading {
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
    margin: 0 auto 0 0;
    font-weight: 600;
    opacity: 90%;
}
.edit-main-data-user__time-zone-select-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 2.66em;
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    box-sizing: border-box;
    border-radius: 0.4em;
    cursor: pointer;
}
.edit-main-data-user__time-zone-select-value {
    line-height: 133%;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    margin: auto auto auto 1.3em;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.edit-main-data-user__time-zone-select-arrow {
    width: 1vw;
    height: 1vh;
    object-fit: cover;
    margin: auto 1.33em auto auto;
}
.edit-main-data-user__time-zone-options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #FFFFFF;
    overflow-y: scroll;
    top: 3.22em;
    left: -1px;
    width: 100%;
    max-height: 11em;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 1.33em 1.33em;
    cursor: default;
    z-index: 9;
}
.edit-main-data-user__time-zone-options-container::-webkit-scrollbar {
    width: 0.22em;
    background: #FFFFFF;
    border-radius: 4px;
}
.edit-main-data-user__time-zone-options-container::-webkit-scrollbar-thumb {
    background: #CFD4DD;
    border-radius: 4px;
}
.edit-main-data-user__time-zone-option {
    line-height: 1.33em;
    color: #363B4D;
    opacity: 0.9;
    margin: 1.33em auto 0 0;
}
.edit-main-data-user__time-zone-option:hover {
    cursor: pointer;
    opacity: 0.6;
}
.edit-main-data-user__select-time-zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.edit-main-data-user__select-time-zone select {
    background-color: white;
    position: absolute;
    left: 10em;
    top: 2px;
    cursor: pointer;
    outline: none;
    width: 60px;
}
.edit-main-data-user__select-time-zone span {
    z-index: 3;
}
.edit-main-data-user__select-time-zone__red-star-icon {
    display: none;
}
/*------Контейнер для блока с паролем---------------------------------------------------------------------------------*/
.edit-main-data-user__title-change-pass {
    margin-bottom: 1.33em;
    font-weight: 600;
    font-size: 1em;
    color: #363B4D;
    opacity: 90%;
}
.edit-main-data-user__pass {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 0.88em;
    position: relative;
}
.edit-main-data-user__new-pass {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 0.88em;
    position: relative;
}
.edit-main-data-user__repeat-pass {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
    margin-bottom: 1.33em;
    position: relative;
}
.edit-main-data-user__pass-label,
.edit-main-data-user__new-pass-label,
.edit-main-data-user__repeat-pass-label {
    font-size: 1em;
    color: rgba(54, 59, 77, 0.9);
    line-height: 133%;
}
.edit-main-data-user__pass-input,
.edit-main-data-user__new-pass-input,
.edit-main-data-user__repeat-pass-input {
    border-radius: 0.44em;
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    outline: none;
    color: #363B4D;
    opacity: 90%;
    line-height: 133%;
    font-size: 1em;
    padding: 0.66em 1.33em;
    height: 2.66em;
}
.edit-main-data-user__button-save {
    cursor: pointer;
    font-size: 0.88em;
    line-height: 124%;
    color: #0084FE;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #0084FE;
    height: 3em;
}
.edit-main-data-user__button-save:hover {
    background-color: #0084FE;
    color: #FFFFFF;
}
.edit-main-data-user__pass-icon-show {
    position: absolute;
    width: 1.22em;
    height: 0.83em;
    bottom: 0.83em;
    right: 0.83em;
    cursor: pointer;
}
.edit-main-data-user__repeat-pass-icon-show {
    position: absolute;
    width: 1.22em;
    height: 0.83em;
    bottom: 0.83em;
    right: 0.83em;
    cursor: pointer;
}
.edit-main-data-user__new-pass-icon-show {
    position: absolute;
    width: 1.22em;
    height: 0.83em;
    bottom: 0.83em;
    right: 0.83em;
    cursor: pointer;
}
/*------Контейнер для блока с организациями и группами----------------------------------------------------------------*/
.profile-user__organizations-groups-users-votes {
    display: flex;
    flex-direction: column;
    padding: 1.33em 1.33em;
    box-shadow: -1px 0 8px rgba(54, 58, 72, 0.07);
    background-color: #FFFFFF;
    border-radius: 1.33em;
    width: 61.111vw;
    align-self: flex-start;
}
.profile-user__switch-button {
    display: initial;
}
.profile-user__switch-button_mobile {
    display: none;
}
.organizations-groups-users-votes__toggle-buttons {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EEEEEE;
    gap: 2em;
    margin-bottom: 1.33em;
}
.toggle-buttons__org-group-users {
    font-size: 1.11em;
    line-height: 140%;
    font-weight: 600;
    color: #363B4D;
    opacity: 90%;
}
.organizations-groups-users-votes__org-groups-list {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.org-groups-list__title-icon-status {
    display: flex;
    flex-direction: row;
    gap: 1.77em;
    justify-content: space-between;
    /*margin-bottom: 0.88em;*/
}
.title-icon-status__title {
    font-size: 1em;
    line-height: 156%;
    color: #363B4D;
    opacity: 90%;
    cursor: pointer;
    word-break: break-word;
}
.title-icon-status__title:hover {
    color: #0084FE;
}
.title-icon-status__icon-status-block {
    display: flex;
    align-items: center;
    border: 0.5px solid #F9C521;
    border-radius: 1.33em;
    height: 1.33em;
    padding: 0.11em 0.88em;
    gap: 0.33em;
}
.title-icon-status__icon {
    width: 0.72em;
    height: 0.72em;
}
.title-icon-status__status {
    font-size: 0.66em;
    color: #F9C521;
    line-height: 167%;
}
.org-groups-list__select-row-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.94em;
    /*margin-bottom: 1.33em;*/
    cursor: pointer;
}
.select-row-list__label {
    font-size: 1em;
    font-weight: 600;
    color: #363B4D;
    opacity: 90%;
    line-height: 133%;
}
.select-row-list__icon-row {
    width: 0.6em;
    height: 0.8em;
    transform: rotate(180deg);
}
.select-row-list__icon-row.active {
    width: 0.6em;
    height: 0.8em;
    transform: rotate(0deg);
}
.org-groups-list__list-activity-user {
    display: none;
}
.org-groups-list__list-activity-user.active {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.list-activity-user__name-group {
    font-size: 1em;
    line-height: 156%;
    color: #363B4D;
    opacity: 90%;
    cursor: pointer;
    word-break: break-word;
    /*margin-bottom: 0.88em;*/
}
.list-activity-user__name-group:hover {
    color: #0084FE;
}
.list-activity-users__show-all-btn {
    padding-bottom: 1.33em;
    font-weight: 300;
    color: #0084FE;
    line-height: 150%;
    font-size: 0.88em;
    cursor: pointer;
    border-bottom: 1px solid #EEEEEE;
}
/*------Контейнер для блока с голосованиями---------------------------------------------------------------------------*/
.profile-user__votes {
    display: flex;
    flex-direction: column;
    gap: 0.88em;
}
.profile-user__votes__vote-event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 0.88em;
}
.profile-user__votes__vote-event-name-event {
    display: flex;
    flex-direction: column;
    gap: 0.44em;
}
.profile-user__votes-name-event {
    color: rgba(54, 59, 77, 0.9);
    font-size: 1em;
    line-height: 133%;
    cursor: pointer;
}
.profile-user__votes-name-event:hover {
    color: #0084FE;
}
.profile-user__votes-name-org {
    font-size: 0.88em;
    color: rgba(54, 59, 77, 0.4);
}
.profile-user__votes-empty-message {
    font-size: 1em;
    color: rgba(54, 59, 77, 0.4);
}
.profile-user__votes__roles-user {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.44em;
}
.profile-user__votes__role-user-voter {
    color: #49B3FF;
    font-size: 0.66em;
    border: 1px solid #49B3FF;
    font-weight: 500;
    padding: 0.2em 0.88em 0.2em 0.88em;
    border-radius: 1.33em;
    text-align: center;
    max-width: 109px;
}
.profile-user__votes__role-user-observer {
    color: #4569FF;
    font-size: 0.66em;
    border: 1px solid #4569FF;
    font-weight: 500;
    padding: 0.2em 0.88em 0.2em 0.88em;
    border-radius: 1.33em;
    text-align: center;
    max-width: 114px;
}
.profile-user__votes__role-user-counter {
    color: #4ED4A9;
    font-size: 0.66em;
    border: 1px solid #4ED4A9;
    font-weight: 500;
    padding: 0.2em 0.88em 0.2em 0.88em;
    border-radius: 1.33em;
    text-align: center;
    white-space: nowrap;
    max-width: 135px;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
    .container__profile-user  {
        font-size: 16px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 1120px) {
    .container__profile-user  {
        font-size: 12px;
        min-height: calc(65vh - 80px);
    }
}
@media (max-width: 830px) {
    .container__profile-user  {
        font-size: 10px;
        min-height: calc(65vh - 80px);
    }
    .profile-user__switch-button {
        display: none;
    }
    .profile-user__switch-button_mobile {
        display: initial;
    }
}
@media (max-width: 600px) {
    .container__profile-user  {
        font-size: 8px;
        min-height: calc(65vh - 80px);
    }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .container__profile-user {
        margin-bottom: 29px;
    }
    .profile-user {
        flex-direction: column;
        gap: 16px;
    }
    .profile-user__error-message {
        font-size: 12px;
    }
    .profile-user__personal-data-user {
        width: 100%;
    }
    .personal-data-user__icon-photo {
        width: 100%;
        height: 170px;
    }
    .main-data-user__username {
        font-size: 16px;
        line-height: 138%;
        font-weight: 400;
        margin-bottom: 4px;
    }
    .main-data-user__email {
        font-size: 16px;
        font-weight: 400;
        line-height: 138%;
        margin-bottom: 8px;
    }
    .main-data-user__timezone {
        margin-bottom: 16px;
    }
    .main-data-user__timezone {
        align-items: center;
    }
    .main-data-user__timezone-icon {
        width: 13px;
        height: 13px;
    }
    .main-data-user__timezone-current {
        font-size: 14px;
        font-weight: 400;
        line-height: 171%;
    }
    .main-data-user__edit-button {
        height: 48px;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        margin-bottom: 8px;
    }
    .edit-main-data-user__email {
        font-size: 16px;
        font-weight: 400;
        line-height: 138%;
        margin-bottom: 16px;
    }
    .edit-main-data-user__title-change-data {
        font-weight: 500;
        line-height: 150%;
        font-size: 16px;
        margin-bottom: 16px;
    }
    .edit-main-data-user__surname,
    .edit-main-data-user__name {
        margin-bottom: 16px;
        gap: 8px;
    }
    .edit-main-data-user__middle-name {
        margin-bottom: 24px;
        gap: 8px;
    }
    .edit-main-data-user__surname-label,
    .edit-main-data-user__name-label,
    .edit-main-data-user__middle-name-label
    {
        font-size: 16px;
        line-height: 138%;
    }
    .edit-main-data-user__surname-field-input,
    .edit-main-data-user__name-field-input,
    .edit-main-data-user__middle-name-field-input {
        font-size: 18px;
        height: 48px;
    }
    .edit-main-data-user__time-zone-heading {
        font-size: 16px;
        line-height: 150%;
        font-weight: 500;
    }
    .edit-main-data-user__time-zone-select-container {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .edit-main-data-user__title-change-pass {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 24px;
        line-height: 150%;
    }
    .edit-main-data-user__pass-label,
    .edit-main-data-user__new-pass-label,
    .edit-main-data-user__repeat-pass-label {
        font-size: 16px;
        line-height: 138%;
    }
    .edit-main-data-user__pass-input,
    .edit-main-data-user__new-pass-input,
    .edit-main-data-user__repeat-pass-input {
        font-size: 16px;
        height: 48px;
        padding: 13px 38px 13px 16px;
    }
    .edit-main-data-user__pass-icon-show,
    .edit-main-data-user__new-pass-icon-show,
    .edit-main-data-user__repeat-pass-icon-show {
        width: 22px;
        height: 15px;
        bottom: 15px;
        right: 12px;
    }
    .edit-main-data-user__pass,
    .edit-main-data-user__new-pass,
    .edit-main-data-user__repeat-pass {
        margin-bottom: 24px;
        gap: 8px;
    }
    .edit-main-data-user__button-save {
        height: 48px;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        margin-bottom: 8px;
    }
    .organizations-groups-users-votes__toggle-buttons {
        margin-bottom: 32px;
    }
    .toggle-buttons__org-group-users {
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;
        line-height: 144%;
    }
    .org-groups-list__title-icon-status {
        /*flex-direction: column;*/
        align-items: baseline;
        gap: 8px;
        margin-bottom: 8px;
    }
    .title-icon-status__title {
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 138%;
    }
    .title-icon-status__icon {
        width: 10px;
        height: 10px;
    }
    .title-icon-status__status {
        font-size: 10px;
        line-height: 167%;
    }
    .title-icon-status__icon-status-block {
        max-width: 116px;
        height: 24px;
    }
    .org-groups-list__select-row-list {
        margin-bottom: 8px;
    }
    .select-row-list__label {
        font-size: 12px;
        font-weight: 600;
        line-height: 138%;
    }
    .select-row-list__icon-row,
    .select-row-list__icon-row.active {
        width: 8px;
        height: 13px;
    }
    .list-activity-user__name-group {
        font-size: 12px;
        line-height: 138%;
        margin-bottom: 8px;
    }
    .list-activity-users__show-all-btn {
        font-size: 16px;
        line-height: 150%;
        padding-bottom: 16px;
    }
    .profile-user__organizations-groups-users-votes {
        width: 100%;
    }
    .profile-user__votes__vote-event {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-bottom: 1px solid darkgray;
        padding-bottom: 8px;
        overflow: auto;
    }
    .profile-user__votes__vote-event:last-child {
        border-bottom: none;
    }
    .profile-user__votes__vote-event-name-event {
        gap: 8px;
    }
    .profile-user__votes-name-event {
        font-size: 14px;
        word-break: break-word;
        line-height: 100%;
    }
    .profile-user__votes-name-org {
        font-size: 12px;
        word-break: break-word;
    }
    .profile-user__votes__roles-user {
        flex-direction: row;
        gap: 8px;
    }
    .profile-user__votes__role-user-voter,
    .profile-user__votes__role-user-observer,
    .profile-user__votes__role-user-counter {
        font-size: 10px;
    }
    .profile-user__votes-empty-message {
        font-size: 16px;
    }
}